export default {
  'slot1': "9478795307",
  'slot2': "3679990944",
  'slot3': "4129415640",
  'firebaseConfig': {
  apiKey: "AIzaSyAfkvefSmg_fRhGiL-hFg1dUGQxmwvTViA",
  authDomain: "news11-media.firebaseapp.com",
  projectId: "news11-media",
  storageBucket: "news11-media.appspot.com",
  messagingSenderId: "714927957552",
  appId: "1:714927957552:web:265590d928631788c6eff5",
  measurementId: "G-FEH81N4L01"
  }
}